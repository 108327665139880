<template>
  <div class="AccountSaved" >
    <div class="container-fluid text-center">
      <h1
        class="gavc-h1 text-center"
        style="margin-top: 150px;"
      >
        Félicitations, votre compte a bien été créé 🙂
      </h1>
      <p
        v-if="cedant.entreprise_cedee.offre_cession.annonce"
        class="gavc-chapo mt-4"
      >
        Vous pouvez dès maintenant créer et publier votre annonce pour utiliser pleinement l'application Garance-à-vos-côtés
      </p>
      <p
        v-else
        class="gavc-chapo mt-4"
      >
        Vous pouvez dès maintenant accéder à votre tableau de bord et utiliser pleinement l'application Garance-à-vos-côtés
      </p>

      <div class="row mt-2" style="margin-bottom: 200px;">
        <div class="col-12 text-center">
          <button
            v-if="cedant.entreprise_cedee.offre_cession.annonce"
            class="gavc-btn mt-2"
            @click="$router.push(`/cedant/offre-cession/annonce/${cedant.entreprise_cedee.offre_cession.annonce.id}/edition`)">
            Je crée mon annonce
          </button>
          <button
            v-else
            class="gavc-btn mt-2"
            @click="$router.push(`/cedant/tableau-de-bord`)">
            J'accède à mon tableau de bord
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  APICedant
} from '@/api/APICedant'

const apiCedantService = new APICedant()

export default {
  name: 'AccountSaved',
  data: function() {
    return {
      cedant: {
        entreprise_cedee: {
          address: '',
          capital: '',
          completed_rate: 0,
          forme_sociale: '',
          name: '',
          offre_cession: {
            annonce: null,
            candidatures: []
          },
          phone_number: '',
          siren: ''
        },
        company: {
          completed_rate: 0
        },
        address: '',
        birthdate: '',
        city: '',
        completed_rate: 0,
        phone_number: '',
        user: {
          first_name: '',
          last_name: '',
          username: '',
        },
        zip_code: '',
      },
    }
  },
  mounted () {
    if (this.$cookies.get('cookie-analysis')) {
      const tagManagerScript = document.createElement('script')
      tagManagerScript.setAttribute('async', true)
      tagManagerScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=AW-10883104919")

      const tagManagerConfigScript = document.createElement('script')
      const tagManagerConfigScriptInlineCode = document.createTextNode(
        `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'AW-10883104919');`
      )
      tagManagerConfigScript.appendChild(tagManagerConfigScriptInlineCode);

      this.tagManagerScript = document.body.appendChild(tagManagerScript)
      this.tagManagerConfigScript = document.body.appendChild(tagManagerConfigScript)
    }

    if (this.$cookies.get('cookie-social-network-analyses')) {
      window.fbq('track', 'Inscription')
    }

    this.getConnectedCedant()

  },
  beforeDestroy() {
    if (this.$cookies.get('cookie-analysis')) {
      document.body.removeChild(this.tagManagerScript)
      document.body.removeChild(this.tagManagerConfigScript)
    }
  },
  computed: {
    token() {
      return this.$store.state.auth.token
    },
  },
  methods: {
    getConnectedCedant() {
      this.$store.commit('loadFromAPI')
      apiCedantService.getConnectedCedant(this.token)
        .then((result) => {
          this.cedant = result.data
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.$store.commit('endLoadingFromAPI')
        })
    }
  }
}
</script>
